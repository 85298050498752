import React from 'react'

export const Logo = () => {

  return (
    <> 
      <img   style={{width: '20%', cursor: 'pointer', marginTop: '8px',marginBottom: '8px', display:'flex', justifyContent: 'center' }} src="https://i.ibb.co/tJtJSBp/1.jpg" alt="logo" />
    </>
  )
}
